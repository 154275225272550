const riot = require('riot');
// mixin

// selectionList
riot.mixin('selectionList', {
  init() {
    // 選択リスト用の管理オブジェクトを初期化
    this.selectionList = {
      selectedIndex: 0,
      hoverSelectionDisabled: false,
      // onkeydown に仕込んでください
      onKeyDown: (e) => {
        this.trigger('selectionList.keydown', e);
        if (e.preventSelectionList) return;
        var code = e.keyCode;
        var isArrowUp = code === 38;
        var isArrowDown = code === 40;
        var isEnter = code === 13;
        var isEsc = code === 27;
        var isTab = code === 9;
        var isSpace = code === 32;

        // 上下選択 ↑↓ control + p,n
        if (e.ctrlKey) {
          if (e.key === 'p') {
            this.selectionList.selectPrev(e);
            return;
          }
          else if (e.key === 'n') {
            this.selectionList.selectNext(e);
            return;
          }
        }
        // Command + Enter の処理
        if (e.metaKey && isEnter) {
          this.trigger('selectionList.keydown.commandEnter', e);
          return;
        }
        if (isEnter) {
          this.trigger('selectionList.keydown.enter', e);
          return;
        }
        if (!e.shiftKey && !e.altKey && !e.metaKey && !e.ctrlKey) {
          if (isArrowUp) {
            // ひとつ上を選択
            this.selectionList.selectPrev(e);
          }
          else if (isArrowDown) {
            //- ひとつ下を選択
            this.selectionList.selectNext(e);
          }
          else if (isEsc) {
            this.trigger('selectionList.keydown.esc', e);
          }
          else if (isTab) {
            this.trigger('selectionList.keydown.tab', e);
          }
          else if (isSpace) {
            this.trigger('selectionList.keydown.space', e);
          }
          else {
            this.trigger('selectionList.keydown.other', e);
            e.preventUpdate = true;
          }
        }
      },

      getItems: () => {
        const TAG_NAME = 'item-selection-list';
        return this.root.querySelectorAll(`${TAG_NAME}, [data-is="${TAG_NAME}"]`);
      },

      getSelectedItem: () => {
        return this.selectionList.getItems()[this.selectionList.selectedIndex];
      },

      isSelected: (element) => {
        return this.selectionList.getSelectedItem() === element;
      },

      getIndexByElement: (element) => {
        const items = this.selectionList.getItems();
        for (let i = 0; i < items.length; ++i) {
          if (items[i] === element) return i;
        }
        return -1;
      },

      // onmousemove, onmouseenter
      onItemSelect: (e) => {
        const target = e.currentTarget.parentElement;
        if (!this.selectionList.hoverSelectionDisabled && !this.selectionList.isSelected(target)) {
          this.selectionList.selectedIndex = this.selectionList.getIndexByElement(target);
          this.update();
        }
        e.preventUpdate = true;
      },

      // 前の要素を選択
      selectPrev: (e) => {
        e.preventDefault();
        const { selectionList } = this;
        selectionList.selectedIndex = Math.max(0, selectionList.selectedIndex - 1);
        selectionList.scrollToSelectedItem();
      },

      // 次の要素を選択
      selectNext: (e) => {
        e.preventDefault();
        const { selectionList } = this;
        selectionList.selectedIndex = Math.min(selectionList.getItems().length - 1, selectionList.selectedIndex + 1);
        selectionList.scrollToSelectedItem();
      },

      // 選択中の要素を画面内にスクロールする
      scrollToSelectedItem: () => {
        const item = this.selectionList.getSelectedItem();
        if (item) {
          // 別の要素にホバーしてるときにそっちが選択されないよう対策
          this.selectionList.disableHoverSelection();
          item.scrollIntoView({ inline: "nearest", block: "nearest" });
          this.selectionList.enableHoverSelection()
        }
      },

      disableHoverSelection: () => {
        this.selectionList.hoverSelectionDisabled = true;
      },

      enableHoverSelection: () => {
        requestAnimationFrame(() => {
          this.selectionList.hoverSelectionDisabled = false;
        });
      },
    };

    this.on('updated', () => {
      this.selectionList.disableHoverSelection();
      // index を 範囲内に調整
      const MAX = this.selectionList.getItems().length - 1;
      if (MAX === -1) return;
      if (this.selectionList.selectedIndex > MAX) {
        this.selectionList.selectedIndex = MAX;
        this.update();
      }
    });

    this.on('updated', () => {
      this.selectionList.enableHoverSelection();
    });
  }
});


// onsubmit とかを trigger で発火するようにする (ただしデフォルトのDOMイベントのバブリングと被らないように注意が必要)
riot.mixin('enableOptsEventHandler', {
  init() {
    this.on('*', (type, ...args) => {
      const f = this.opts[`on${type}`];
      if (f) {
        f.call(this, ...args);
      }
    });
  }
});