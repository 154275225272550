const riot = require('riot');

riot.tag2('module-comment-form', '<form class="f fclm fbw h-full py12 px16" onsubmit="{onPostComment}" onclick="{refs.editor.focus}"> <div class="h-full overflow-hidden" ref="editor" data-is="atom-editor" project="{opts.project}" mentions="{opts.mentions}" placeholder="コメントを入力"></div> <div class="f fbw fm flex-fixed pt8"> <div ref="toolbar" data-is="atom-editor-toolbar"></div> <button class="relative f fh flex-fixed s32 circle tooltip {canSubmit() ? &quot;primary_fill&quot; : &quot;disabled_fill&quot;}" aria-label="送信" data-tooltip-position="top"><i class="fs20 icon-{opts.label ? &quot;check&quot; : &quot;send&quot;}"></i> <div class="absolute tn6 b0 l0 r0 s32" if="{refs.editor.isUploading}" data-is="atom-loading"></div> </button> </div> </form>', 'module-comment-form,[data-is="module-comment-form"]{display:block}', '', function(opts) {
    this.on('mount', () => {
      this.refs.editor.on('editorCommand', this.onEditorCommand);
      this.refs.editor.on('change', () => {
        this.trigger('editorchange');
        this.update();
      });
      this.refs.editor.on('submit', () => {
        this.postComment();
      });
      this.refs.toolbar.on('command', ({command, e}) => {
        if (command === 'note') {
          this.onToggleNotesPopup(e);
        }
      });
      this.refs.toolbar.setEditor(this.refs.editor);
      this.refs.toolbar.setEmojiPopupTarget(this.refs.editor.root);
    });

    this.on('unmount', () => {
      if (this.refs.editor) {
        this.refs.editor.off('editorCommand', this.onEditorCommand);
      }
    });

    this.setOption = (key, value) => {
      this.refs.editor.editor.setOption(key, value);
    };

    this.focusEditor = () => {
      this.refs.editor.focus();
    };

    this.onPostComment = (e) => {
      e.preventDefault();
      this.postComment();
    };

    this.postComment = () => {

      if (!this.canSubmit()) return ;

      capacitor.hapticsImpactLight();
      const editor = this.refs.editor;
      const value = editor.getValue().trim();

      editor.setValue('');
      this.trigger('commentpost', {value});
    };

    this.canSubmit = () => {
      return !!this.refs.editor.getValue().trim() && !this.refs.editor.isUploading;
    };

    this.setValue = (value) => {
      this.refs.editor.setValue(value);
    };

    this.getValue = () => {
      return this.refs.editor.getValue();
    };

    this.onEditorCommand = async ({command, editor, data, completion, tag}) => {

      if (command === 'link') {
        this.tags["atom-link"].onOpenLinkModal();
      }

      if (command === 'note') {
        this.onToggleNotesPopup();
      }

      if (command === 'assign') {
        this.openPopupAssignUsers();
      }
      if (command === 'table') {
        app.utils.openCursorPopupTable(this.refs.editor);
      }
    };

    this.onToggleNotesPopup = async (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      const $cursor = this.refs.editor.getCursor();
      const cursorPosition = $cursor.getBoundingClientRect();
      const popup = spat.popup.open('popup-suggestion-notes', {
        offsetTop: cursorPosition.bottom - 300,
        offsetLeft: cursorPosition.left - 40,
        closeTarget: e ? e.currentTarget : '',
      });
      const selected_notes = await popup.waitClose();

      if (selected_notes?.length) {
        const promises = selected_notes.map(async (selected_note) => {
          let title = selected_note.data.title;

          const project = await selected_note.getProject().fetch();
          title += ` - ${project.data.name}`;

          let list_symbol = selected_notes.length > 1 ? '- ' : '';
          const formatted_title = list_symbol + app.utils.getInsertLinkToTitle({
            title,
            url: `/${selected_note.path}`,
          });

          return formatted_title;
        });

        const titles = await Promise.all(promises);
        const text = titles.join('\n');

        this.refs.editor.insertTextAtCursor(text);

        this.refs.editor.focus();
      }
    };

    this.openPopupAssignUsers = async () => {
      const $cursor = this.refs.editor.getCursor();
      const cursorPosition = $cursor.getBoundingClientRect();

      var assigned_user_refs = this.opts.note.data.assigned_user_refs;

      var project_users = await this.opts.note.parent.parent.fetchProjectUsers();
      const popup = spat.popup.open('popup-suggestion-users', {
        offsetTop: cursorPosition.bottom,
        offsetLeft: cursorPosition.left -40,
        opts: {
          label: 'アサインユーザーを編集',
          users: project_users,
          selectedUsersIds: assigned_user_refs.map(user => user.id),
        },
      }).on('userselect', async ({user, selectedUsers, checked}) => {
        try {
          await this.opts.note.assign(selectedUsers.map(user => user.id));

          app.utils.openToast(checked ? 'assignedUpdate' : 'unassignedUpdate', {label: user.data.screen_name});
        }
        catch(e) {
          spat.modal.alert('更新に失敗しました');
        }
        this.update();
      }).on('close', () => {
        this.focusEditor();
      });
    };
});