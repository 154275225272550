const riot = require('riot');

riot.tag2('atom-raw', '', '', '', function(opts) {
    this.on('mount', () => {
      if (spat.isNode) return;
      this.root.innerHTML = this.opts.content;
    });

    this.on('update', () => {
      this.root.innerHTML = this.opts.content;
    });
});