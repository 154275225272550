const riot = require('riot');

riot.tag2('atom-task-progress', '<div class="f ft tooltip {isComplete ? &quot;text-green&quot; : &quot;text-deep&quot;}" if="{totalCheckboxesCount !== null}" aria-label="{getToolTipMessage()}" data-tooltip-position="top"><i class="fs18 pt2 mr4 {isComplete ? &quot;icon-circle_check&quot; : &quot;icon-remind&quot;}"></i> <div class="fs13 lh16"><span class="mr4 s-hide">進捗率</span><span>{progress}%</span></div> </div>', '', '', function(opts) {
    this._prevContent = '';
    this.totalCheckboxesCount = null;

    this.getToolTipMessage = () => {
      return `✅ :  ${this.checkedCheckboxesCount} / ${this.totalCheckboxesCount} \n 進捗率とは、チェックボックスの数に対してチェックが入っている数の割合です。`;
    };

    this.on('update', () => {

      if (this.opts.content !== this._prevContent) {
        this.debouncedCalculateProgress();
        this._prevContent = this.opts.content;
      }
    });

    this.debouncedCalculateProgress = _.debounce(() => {
      this.calculateProgress();
    }, 32);

    this.reset = () => {
      this.totalCheckboxesCount = null;
      this.checkedCheckboxesCount = null;
      this.progress = 0;
      this.isComplete = false;
    };

    this.calculateProgress = () => {
      this.reset();
      const content = this.opts.content;
      if (!content) return ;

      this.totalCheckboxesCount = (content.match(/\[ \]/g) || []).length + (content.match(/\[x\]/g) || []).length;

      if (this.totalCheckboxesCount === 0) {
        this.reset();
        return ;
      };

      this.checkedCheckboxesCount = (content.match(/\[x\]/g) || []).length;

      const progress = (this.checkedCheckboxesCount / this.totalCheckboxesCount) * 100;

      this.progress = progress.toFixed(0);

      this.isComplete = this.progress >= 100;

      this.update();
      return progress.toFixed(0);
    };
});