const riot = require('riot');

riot.tag2('module-note', '<div class="f flex-column s-full overflow-hidden"> <div class="px16 pt12" ref="header" data-is="module-note-header" show-arrow-button="{opts.showArrowButton}"></div> <div class="relative s-full"> <div class="absolute trbl0 pl4 pt8 pb40 z0"> <div class="w-full h-full" ref="editor" data-is="atom-editor" project="{project}" mentions="{mentions}" should-show-toc="{true}"></div> </div> <div class="absolute l8 b0 f w-full {&quot;b100&quot; : isShowTemplateButton()}"> <div class="w-full f px16 pt8 pb15 bg-white" ref="toolbar" data-is="atom-editor-toolbar"></div> </div> <div class="absolute trbl0 pl4 pt8 z0 pointer-none" show="{isShowEmptyView()}"> <div class="text-label_dark_deep opacity-50" ref="placeholder" data-is="atom-editor" is-placeholder="{true}"></div> </div> <div class="w-full bg-white absolute p8 b0 z1" if="{isShowTemplateButton()}"> <div class="bold lh15 px12">テンプレートを選択</div> <div class="f overflow-x-scroll pb8"> <div class="max-width-375 w-full p8" each="{item in templates}" if="{item.data.title.length}"> <button class="w-full f fh flex-fixed button px12 py8 primary transition" onclick="{selectTemplate}" onmouseenter="{onSetPlaceholder}" onmouseleave="{onRemovePlaceholder}"><i class="icon-template mr8 fs20"></i> <div class="lh15 word-break-keep line-clamp-1">{item.data.title}</div> </button> </div> </div> </div> </div> </div>', 'module-note,[data-is="module-note"]{display:block}', '', function(opts) {
    this.on('mount', () => {
      this.refs.editor.on('change', () => {
        this.update();
        this.debouncedSave();
      });
      this.refs.editor.on('submit', () => {
        this.refs.editor.blur();
      });
      this.refs.editor.on('editorCommand', this.onEditorCommand);
      this.refs.toolbar.on('command', ({command, e}) => {
        if (command === 'note') {
          this.openPopupSuggestionNotes(e);
        }
      });
      this.refs.toolbar.setEditor(this.refs.editor);
      this.refs.header.on('arrowclick', (e) => {
        const offset = e.type === 'left' ? -1 : 1;
        this.trigger('pagination', { offset });
      });
    });
    this.on('unmount', () => {
      if (this.note) {
        this.note.unwatch();
        this.note.removeAllListeners();
      }
      if (this.refs.editor) {
        this.refs.editor.off('change', this.debouncedSave);
        this.refs.editor.off('editorCommand', this.onEditorCommand);
      }
    });

    this.setNote = async (note, {autofocus=false}={}) => {
      if (this.note) {
        this.note.unwatch();
        this.note.removeAllListeners();

        this.debouncedSave.cancel();
      }

      this.note = note;
      await this.note.fetch({cache: false, relation: true});

      if (this.note !== note) {
        return ;
      }

      this.update();

      this.refs.header.setNote(this.note);
      this.refs.editor.setValue(this.note.data.content);

      this.refs.editor.editor.clearHistory();

      if (!this.note.data.title) {
        this.refs.placeholder.setValue(app.data.note.placeholder.empty_view);
        this.refs.editor.setCursorToLast();
        this.refs.editor.focus();
      }

      this.project = await this.note.getProject().fetch();

      document.documentElement.style.setProperty('--project-color', this.project?.getColor() || '#4466ea');

      this.update();

      this.mentions = await this.note.getProject().fetchMentions();
      this.update();

      this.templates = await this.note.getProject().fetchTemplates();
      this.isShowTemplateButton();
      this.update();

      this.note.watch();
      this.note.on('snapshot', () => {
        this.update();
      });
    };

    this.save = async () => {
      if(!this.note || !this.refs.editor) return ;

      var content = this.refs.editor.getValue();

      if (this.note.data.content === content) return ;

      var title = app.utils.getTitle(content);
      var thumbnail = app.utils.getImage(content);

      var res = await app.api.child(this.note.path).put({
        title: title,
        content: content,
      });
      this.refs.placeholder.setValue(app.data.note.placeholder.empty_view);
      this.update();
    };

    this.debouncedSave = _.debounce(() => {
      this.save();
    }, 256);

    this.openPopupAssignUsers = async () => {
      const $cursor = this.refs.editor.getCursor();
      const cursorPosition = $cursor.getBoundingClientRect();
      var assigned_user_refs = this.note.data.assigned_user_refs;
      var project_users = await this.note.parent.parent.fetchProjectUsers();
      const popup = spat.popup.open('popup-suggestion-users', {
        offsetTop: cursorPosition.bottom,
        offsetLeft: cursorPosition.left -40,
        opts: {
          label: 'アサインユーザーを編集',
          users: project_users,
          selectedUsersIds: assigned_user_refs.map(user => user.id),
        },
      }).on('userselect', async ({user, selectedUsers, checked}) => {
        try {
          await this.note.assign(selectedUsers.map(user => user.id));

          app.utils.openToast(checked ? 'assignedUpdate' : 'unassignedUpdate', {label: user.data.screen_name});
        }
        catch(e) {
          spat.modal.alert('更新に失敗しました');
        }
        this.update();
      }).on('close', () => {
        this.refs.editor.focus();
      });
    };

    this.selectTemplate = async (e) => {
      this.update();

      var content = await app.utils.getTemplateContent(e.item.item);

      this.refs.editor.setValue(content);
      this.refs.editor.focus();
    };

    this.isShowEmptyView = () => {
      if(!this.note || !this.refs.editor) return false;

      var content = this.refs.editor.getValue();
      return !app.utils.getTitle(content);
    };

    this.isShowTemplateButton = () => {
      if (!this.note || !this.refs.editor) return false;

      if (!this.templates.length) return false;

      var content = this.refs.editor.getValue();
      return content.trim() === "" || content.trim() === "#";
    };

    this.onEditorCommand = async ({command, editor, data, completion, tag}) => {
      if (!this.note) return;

      if (command === 'assign') {
        this.openPopupAssignUsers();
      }

      if (command === 'link') {
        this.tags["atom-link"].onOpenLinkModal();
      }

      if (command === 'note') {
        this.openPopupSuggestionNotes();
      }

      if (command === 'table') {
        app.utils.openCursorPopupTable(this.refs.editor);
      }

      if (command === 'templates') {
        this.openPopupTemplates();
      }
    };

    this.openPopupSuggestionNotes = async (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      const $cursor = this.refs.editor.getCursor();
      const cursorPosition = $cursor.getBoundingClientRect();
      const popup = spat.popup.open('popup-suggestion-notes', {
        offsetTop: cursorPosition.bottom,
        offsetLeft: cursorPosition.left -40,
        closeTarget: e ? e.currentTarget : '',
      });
      const selected_notes = await popup.waitClose();
      if (selected_notes?.length) {
        const promises = selected_notes.map(async (selected_note) => {
          let title = selected_note.data.title;

          if (this.note.getProject().id !== selected_note.getProject().id) {
            const project = await selected_note.getProject().fetch();
            title += ` - ${project.data.name}`;
          }

          let list_symbol = selected_notes.length > 1 ? '- ' : '';
          const formatted_title = list_symbol + app.utils.getInsertLinkToTitle({
            title,
            url: `/${selected_note.path}`,
          });

          return formatted_title;
        });

        const titles = await Promise.all(promises);
        const text = titles.join('\n');

        this.refs.editor.insertTextAtCursor(text);

        this.refs.editor.focus();
      }
    };

    this.openPopupTemplates = async (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      const $cursor = this.refs.editor.getCursor();
      const cursorPosition = $cursor.getBoundingClientRect();
      const popup = spat.popup.open('popup-templates', {
        offsetTop: cursorPosition.bottom,
        offsetLeft: cursorPosition.left -40,
        closeTarget: e ? e.currentTarget : '',
        opts: {
          templates: this.templates,
        }
      });
      await popup.waitClose();
      if (popup.value) {
        var content = app.utils.getTemplateContent(popup.value);
        this.refs.editor.setValue(content);
      }
    };

    this.onSetPlaceholder = (e) => {
      var content = app.utils.getTemplateContent(e.item.item);
      this.refs.placeholder.setValue(content);
      this.update();
    };

    this.onRemovePlaceholder = () => {
      this.refs.placeholder.setValue(app.data.note.placeholder.empty_view);
    };
});