
var data = {
  header: {
    //- TODO: linkの差し替え
    views: [
      { id: 'list', label: 'List', icon: 'list', link: 'list', tooltip: 'リスト' },
      { id: 'board', label: 'Board', icon: 'board', link: 'board', tooltip: 'ボード' },
      { id: 'table', label: 'Table', icon: 'table', link: 'table', tooltip: 'テーブル' },
      { id: 'gantt', label: 'Gantt', icon: 'gantt', link: 'gantt', tooltip: 'ガント' },
    ],

    //- TODO: linkの差し替え
    menus: [
      // TODO: projectは一旦確認の為コメントアウト
      //- {icon: 'project', link: '/'},
      { id: 'search', icon: 'search', type: 'function', tooltip: '検索', onclick: () => `/workspaces/${app.store.workspace.id}/search` },
      { id: 'shortcut-help', icon: 'help', type: 'modal', tooltip: 'ヘルプ', },
      { id: 'settings', type: 'link', tooltip: '設定', offset_left: -2 },
    ],
  },
  settings: {
    user: {
      title: 'ユーザー',
      tabs: [
        { id: 'profile', text: 'プロフィール' },
        // TODO: idは仮, 機能追加に合わせて順次変更
        { id: 'workspaces', text: '参加中のワークスペース' },
        // TODO: idは仮, 機能追加に合わせて順次変更
        { id: 'setting', text: '設定' },
      ],
    },
    workspace: {
      tabs: [
        { id: 'overview', text: '概要' },
        // TODO: idは仮, 機能追加に合わせて順次変更
        { id: 'users', text: 'メンバー管理' },
        // TODO: idは仮, 機能追加に合わせて順次変更
        { id: 'groups', text: 'グループ管理' },
      ],
    },
  },
  input: {
    placeholder: {
      display_name: '名前を入力してください',
      id: 'idを入力してください',
      email: 'メールアドレスを入力してください',
      password: 'パスワードを入力してください',
    },
    validation: {
      // 小文字の半角英語文字と数字3文字以上21文字以下、-と_のみ使用可能
      // NOTE: 頭文字の「@」も文字数に含まれるので21文字
      screen_name: /^[0-9a-z_-]{3,21}$/,  // 3~21文字の範囲指定を正規表現内で表現
    },
  },
  tips: {
    //- TODO:文言仮
    note_create: 'template作成方法に関する説明が入ります。\ntemplate作成方法に関する説明が入ります。\ntemplate作成方法に関する説明が入ります。',
    note_move: 'note移動に関する説明。\nnote移動に関する説明。\nnote移動に関する説明。\nnote移動に関する説明。\nnote移動に関する説明。\nnote移動に関する説明。\nnote移動に関する説明。',
    project_name: 'プロジェクト名の前に「グループ名 /」を入れると\nサイドバーで自動的にグループ化されます。',
  },
  note: {
    filter: [
      { id: 'mention', label: 'メンション' },
      { id: 'unread', label: '未読' },
      { id: 'favorite', label: 'お気に入り' },
      { id: 'unassigned', label: '未アサイン' },
    ],
    placeholder: {
      empty_view: '# H1 タイトル \n\n ## ## H2 見出し \n\n - 項目\n\n - 項目\n\n - 項目',
    },
  },
  faq: {
    title: 'よくある質問',
    categories: [
      {
        title: 'alogについて',
        questions: [
          { question: 'alogとは？', answer: 'alogは、スレッド形式のメッセージングアプリとタスク管理ツールを統合したツールです。\nチーム内のタスク管理やコミュニケーションを一つの場所で行えるため、チームの生産性を向上させます。\n\nタスクのステータスや期限、アサイン管理といったさまざまな機能を備え、チームの作業をより効率的に進めることができます。\n\nまた、アクセス権限の設定や、添付ファイルや画像の共有機能など、使いやすさも充実しています。\n\nalogで、チームのコミュニケーションとタスク管理を一気通貫で行い、チームの生産性を飛躍的に向上させましょう！' },
        ],
      },
      {
        title: 'ノートについて',
        questions: [
          { question: '間違えてノートを削除/上書きしてしまった時の対処法を教えてください', answer: 'ノートを復元する方法\n1. ノートの右上にある 3点リーダー を押します。\n2. 「ノートの編集履歴を確認」 を選択します。\n3. 過去の履歴を参考にして、復元したいバージョンを選択し、復元してください。\nこの手順で、誤って削除または上書きしてしまったノートを簡単に復元できます。' },
          { question: 'ノートの編集履歴の確認方法を教えてください', answer: 'ノートの編集履歴の確認方法\n1. ノートの右上にある 3点リーダー を押します。\n2. 「ノートの編集履歴を確認」 を選択します。\nこの手順で、ノートの編集履歴を確認できます。' },
          { question: 'テンプレートノートとは何ですか？', answer: 'テンプレートノートとは、ノート作成時にあらかじめ定められたレイアウトを持つノートのことです。\nテンプレートノートを作成することで、ノート作成時にそのテンプレートを選択し、同じレイアウトのノートを簡単に作成することができます。\nテンプレートノートはプロジェクト別に設定することができ、作成したテンプレートは同じプロジェクトのメンバー全員が利用できます。' },
          { question: 'closeステータスのノートについて教えてください', answer: 'closeステータスのノートは、未読の赤いマークは表示されますが、通常の表示には含まれません。\ncloseステータスのノートをInboxで表示させたい場合、上部にある 「クローズも含む」 のチェックボックスにチェックをつけると表示されます。' },
        ]
      },
      {
        title: 'マークダウンについて',
        questions: [
          { question: 'マークダウンとは何ですか？', answer: 'マークダウンとは、手軽にドキュメントを装飾できるフォーマット（記法）です。\nマークダウンを使用すると、特定の記号を入力するだけで、以下のようなさまざまな装飾表現が可能です。\n見出しの作成\n太字や斜体の文字装飾\nリストの作成\nリンクの挿入\nコードブロック\nなどの装飾表現がシンプルな記法により、誰でも簡単に見やすいドキュメントを作成できます。' },
        ],
      },
      {
        title: 'リマインドについて',
        questions: [
          { question: 'リマインドとは何ですか？', answer: 'リマインド機能は、ユーザーが特定のメッセージやタスクを忘れずに確認できるようにサポートするための便利な機能です。この機能を使うことで、大切な情報や対応が必要なアクションを見逃すことが少なくなります。' },
          { question: 'リマインドが増える条件は何ですか？', answer: 'リマインドは以下のような場合にリマインドリストに追加されます。\n・<strong>個人メンションされた時:</strong> あなた宛に直接メンション（@ユーザー名）があると、リマインドに追加されます。\n・<strong>リマインド対象のグループに含まれていてメンションされた時:</strong> あなたが属するグループがメンションされると（例：@マーケティングチーム @ all）、そのメッセージがリマインドに追加されます。\n・<strong>手動で追加した時:</strong> 任意のメッセージをリマインドに自分で追加することもできます。' },
          { question: 'リマインドが消える条件は何ですか？', answer: 'リマインドは以下のような場合にリマインドリストから消えます。\n・<strong>リアクションをした時:</strong> リマインドされているメッセージにリアクション（例：返信や絵文字など）をすると、そのアクションが完了とみなされリマインドから消えます。\n・<strong>そのメッセージ欄にメッセージした時:</strong> リマインドががあるノートにメッセージを追加すると、問題が完了と見なされリマインドから消えます。\n・<strong>手動で完了にした時:</strong> リマインド一覧から、完了にすることができ、それによってリマインドから消すとこができます。' },
          { question: 'リマインドグループとは何ですか？', answer: 'リマインドグループとは、グループを「リマインドの対象にする」と設定することで、メンションされた際にそのグループに所属するユーザー全員のリマインドに追加される仕組みです。重要なシーンで利用するグループについては、リマインドグループに含めることをお勧めします。' },
          { question: '手動でリマインドに追加する方法を教えて下さい', answer: '手動でリマインドに追加する場合、対象のコメントの３点リーダーを押して、「リマインドに追加」を選択してください。' },
        ]
      },
      {
        title: 'プロジェクトについて',
        questions: [
          { question: 'プロジェクトの参加/追加方法について教えてください', answer: 'プロジェクトの参加/追加方法について\n1. 上部にあるヘッダーのプロジェクト名の隣にある 3点リーダー を押します。\n2. ポップアップが開くので、「プロジェクト編集」 を押します。\n3. プロジェクトに参加させたいユーザーを選択し、下部にある 「更新」 ボタンを押します。\nこの手順で、簡単にプロジェクトにユーザーを参加させたり追加したりできます。' },
          { question: 'プロジェクトの作成方法について教えてください', answer: 'プロジェクトの作成方法について\n1. サイドバーの上部にある 3点リーダー を押します。\n2. ポップアップが開いたら、「プロジェクト作成」 を押します。\n3. プロジェクト名とプロジェクトに参加するメンバーを選択し、下部にある 「作成」 ボタンを押すと、新たにプロジェクトが作成されます。\nこの手順で簡単に新しいプロジェクトを作成できます。' }
        ]
      },
      {
        title: '各種機能について',
        questions: [
          { question: '他のプロジェクトに移動する方法を教えてください', answer: '他のプロジェクトに簡単に移動するためのツールとして「スイッチャー」があります。\n\n1.キーボードで ⌘ + K を押すと、プロジェクト一覧が表示されるモーダルウィンドウが開きます。\n2.モーダルウィンドウから移動したいプロジェクトを選択することで、簡単にプロジェクトを切り替えることができます。\n3.モーダルウィンドウには検索機能も備わっているので、特定のプロジェクトを素早く見つけることができます。' },
          { question: 'スラッシュコマンドの使い方を教えてください', answer: 'スラッシュコマンドを使用するには、以下のいずれかの方法で行います。\n\n・半角英数でスラッシュ（/）を入力する。\n・ノートやコメント欄の下部に並んでいるツールアイコンを押すとポップアップが表示され、使用したい機能を選択できます。\n\n<strong>スラッシュコマンドの一覧と機能</strong>\n\n/today\n機能：/today と入力すると、今日の日付が自動で挿入されます\n\n/tomorrow\n機能：/tomorrow と入力すると、明日の日付が自動で挿入されます。\n\n/yesterday\n機能：/yesterday と入力すると、昨日の日付が自動で挿入されます。\n\n/assign\n機能：/assign と入力すると、ユーザー選択のポップアップが表示され、アサインしたいユーザーを選択できます。\n\n/link\n機能：/link と入力すると、リンク入力フィールドが表示され、URLを入力してリンクを添付できます。\n\n/note\n機能：/note と入力すると、ノートのリンクとタイトルを入力するフィールドが表示されます。\n\n/table\n機能：/table と入力すると、テーブル作成のオプションが表示され、行数と列数を指定してテーブルを作成できます。\n\n/templates\n機能：/templates と入力すると、利用可能なテンプレートの一覧が表示され、選択することでそのテンプレートを使用できます。\n\n/calendar\n機能：/calendar と入力すると、カレンダーが表示され、選択した日付が入力されます。\n\n/image\n機能：/image と入力すると、ファイル選択のポップアップが表示され、添付したい画像やファイルを選択できます。' },
        ],
      },
    ],
  },
  direct_message_name: 'Direct Message',
  sidebar_settings_key: 'sidebar_settings'
};

export default data;