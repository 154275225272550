import flarebase from 'flarebase'
class AlogStoreManager extends flarebase.store.StoreManager {
  constructor() {
    super();

    this.workspaces = null;

    // workspace
    this.workspace_id = null;
    this.workspace = null;
    this.projects = null;

    // project
    this.project_id = null;
    this.project = null;
  }

  watch() {
    if (this._watchPromise) return this._watchPromise;

    this._watchPromise = new Promise(async (resolve) => {
      // user を監視
      this.currentUser = this.collection('users').doc(app.auth.currentUser.uid);
      await this.currentUser.watch();

      // workspacesUsers を監視
      await this.watchWorkspacesUsers();

      resolve();
    });

    return this._watchPromise;
  }

  async unwatch() {
    if (!this._watchPromise) return;

    if (this.workspacesUsersObserver) {
      this.workspacesUsersObserver.unobserve();
      delete this.workspacesUsersObserver;
    }

    this.currentUser.unwatch();
    this.currentUser = null;

    this._watchPromise = null;
  }

  async setup({ workspace_id, project_id, note_id }) {
    var workspace_promise = this.setupWorkspace(workspace_id);
    await workspace_promise;

    var project_promise = Promise.resolve();

    if (workspace_id && project_id) {
      project_promise = this.setupProject(workspace_id, project_id);
    }
    else {
      this.project = null;
      riot.update();
    }

    // workspace と project の取得を待つ
    await Promise.all([workspace_promise, project_promise]);

    // お気に入りの監視
    this.watchFavorites(project_id);
  }

  // TODO: ログアウトしたときに呼ぶ
  reset() {
    // TODO: watch してるやつとか全般解除する
  }

  async setupWorkspace(workspace_id) {
    // 違うワークスペースだった場合
    if (this.workspace && this.workspace.id === workspace_id) return;

    // reset
    this.workspace_user = null;
    this.workspace = null;
    this.projects = null;
    this.project = null;

    var uid = firebase.auth().currentUser.uid;

    if (workspace_id) {
      this.workspace_id = workspace_id;

      var workspace = this.collection('workspaces').doc(this.workspace_id);
      await workspace.fetch();
      this.workspace = workspace;

      var [workspace_user] = await this.collection(`workspaces_users`).where('user_id', '==', uid).where('workspace_id', '==', this.workspace.id).fetch();
      this.workspace_user = workspace_user;
    }
    else {
      var [workspace_user] = await this.collection(`workspaces_users`).where('user_id', '==', uid).fetch({ relation: true });
      this.workspace_user = workspace_user;

      var workspace = this.workspace_user.relation.workspace;
      this.workspace_id = workspace.id;
      this.workspace = workspace;
    }

    // 最後に見た時間を記録しておく
    this.getWorkspaceUser().look();

    // プロジェクト一覧の監視
    this.watchProjectsUsers();

    // inbox を監視
    if (this.inboxesStore) {
      this.inboxesStore.unwatch();
    }
    this.inboxesStore = this.workspace_user.watchInboxes();
    riot.update();
  }

  async setupProject(workspace_id, project_id) {
    // 違うプロジェクトだった場合のみ再度取得する
    if (this.project && this.project.id === project_id) return;

    this.project_id = project_id;

    this.project = null;
    riot.update();

    var workspace_ref = this.collection('workspaces').doc(this.workspace_id);

    var project = await workspace_ref.collection('projects').doc(project_id).fetch();
    this.project = project;

    var [project_user] = await workspace_ref.collection('projects_users')
      .where('user_id', '==', firebase.auth().currentUser.uid)
      .where('project_id', '==', project_id).fetch();

    this.projectUser = project_user;
  }

  async watchWorkspacesUsers() {
    if (this.workspacesUsersObserver) return;

    var uid = firebase.auth().currentUser.uid;
    var store = this.collection(`workspaces_users`).where('user_id', '==', uid);

    this.workspacesUsersObserver = store.observer(() => {
      app.utils.debouncedUpdate();

      this.workspacesUsersObserver.items.forEach(async item => {
        await item.relate();
        app.utils.debouncedUpdate();
      });

      // メンション未読数を集計
      var total_unread_mention_count = this.workspacesUsersObserver.items.reduce((sum, item) => {
        return sum + item.data.unlooked_mention_count;
      }, 0);

      // すべてのワークスペースの mention が既読になっていたら badge を消す
      if (total_unread_mention_count === 0) {
        capacitor.clearBadge();

        // バッジを更新
        if ('setAppBadge' in navigator) {
          navigator.setAppBadge(0);
        }
      }
    });

    // 監視を開始
    await this.workspacesUsersObserver.observe();
  }

  async watchFavorites(project_id) {
    // 同じ project_id だったら何もしない
    if (this.favorites && this.favorites.project_id === project_id) return;

    if (this.favorites) {
      this.favorites.unwatch();
      this.favorites.removeAllListeners();
      delete this.favorites;
    }

    this.favorites = this.workspace_user.collection('inboxes').where('favorite', '==', true);

    // プロジェクトを選択している場合はプロジェクトも考慮する
    if (project_id) {
      this.favorites = this.favorites.where('project_id', '==', project_id);
    }

    // 設定した project_id を記録しておく
    this.favorites.project_id = project_id;

    // お気に入りを監視する
    this.favorites.on('snapshot', () => {
      riot.update();

      this.favorites.items.forEach(async item => {
        await item.relate();
        app.utils.debouncedUpdate();
      });
    }).watch();
  }

  async watchProjectsUsers() {
    if (this.projectsUsersStore) {
      this.projectsUsersStore.unwatch();
      this.projectsUsersStore.removeAllListeners();
      delete this.projectsUsersStore;
    }

    if (!this.workspace) return;

    var uid = firebase.auth().currentUser.uid;

    this.projectsUsersStore = this.workspace.collection('projects_users')
      // .where('project_kind', '==', 'team')
      .where('user_id', '==', uid)
      .where('archived', '==', false)
      .orderBy('project_name');
    // 監視
    this.projectsUsersStore.on('snapshot', () => {
      app.utils.debouncedUpdate();
    }).watch();
  }

  getWorkspaceUser() {
    if (!this.workspace_id) return;
    if (!this.workspacesUsersObserver) return;

    var workspaceUser = this.workspacesUsersObserver.items.find(item => item.data.workspace_id === this.workspace_id);

    return workspaceUser;
  }
};

var store = new AlogStoreManager();

class AlogDocumentStore extends flarebase.store.DocumentStore {
  constructor(item) {
    super(item);
  }

  get api() {
    return app.api.child(this.path);
  }
}

/*
 * workspaces class
 */
store.registerDocumentStoreClass('workspaces', class Workspace extends AlogDocumentStore {
  constructor(item) {
    super(item);
  }
  // ユーザーの配列を返す
  fetchWorkspaceUsers() {
    return app.store.workspace_user.parent.where('workspace_id', '==', this.id).fetch({ relation: true });
  }

  // グループ一覧を取得
  fetchGroups() {
    return this.collection('groups').fetch();
  }

  // グループを作成
  createGroup(data = {}) {
    return this.api.child('groups').post(data);
  }

  // グループを更新
  updateGroup(id, data = {}) {
    return this.api.child(`groups/${id}`).put(data);
  }

  // グループの削除
  deleteGroup(id) {
    return this.api.child(`groups/${id}`).del();
  }

  // プロジェクトを作成
  createProject(data = {}) {
    return this.api.child('projects').post(data);
  }

  // ワークスペースへ招待中のユーザーを再招待する
  resendInvitation(id) {
    return this.api.child(`invites/${id}/resend`).put();
  }

  // ワークスペース内のユーザーのステータスを変更する
  updateUser(id, data) {
    return this.api.child(`users/${id}`).put(data);
  }

  // ワークスペースからメンバーを削除する
  excludeUser(id) {
    return this.api.child(`users/${id}`).del();
  }

  // ワークスペースの削除
  delete() {
    return this.api.del();
  }
});


/*
 * workspace_user class
 */
store.registerDocumentStoreClass('workspaces_users', class WorkspacesUser extends AlogDocumentStore {
  constructor(item) {
    super(item);
  }
  // inbox を監視
  watchInboxes() {
    var inboxesStore = this.collection('inboxes')
      .where('looked', '==', false)
      .orderBy('updated_at', 'desc')
      .limit(128)
      ;

    inboxesStore.on('snapshot', () => {
      app.utils.debouncedUpdate();
    });
    inboxesStore.watch();

    return inboxesStore;
  }

  // 全リマインドを取得する
  fetchAllReminds({ cursor, limit = 32 } = {}) {
    var ref = this.collection('reminds').orderBy('created_at', 'desc');
    if (cursor) {
      // flarebaseにstartAfterがないので一旦これでやる
      ref._ref = ref._ref.startAfter(cursor);
    }
    return ref.limit(limit).fetch({ relation: true });
  }
  // 未リアクションのリマインドを取得する
  fetchNotYetReminds({ cursor, limit = 32 } = {}) {
    var ref = this.collection('reminds').where('done', '==', false).orderBy('created_at', 'desc');
    if (cursor) {
      // flarebaseにstartAfterがないので一旦これでやる
      ref._ref = ref._ref.startAfter(cursor);
    }
    return ref.limit(limit).fetch({ relation: true });
  }

  // 未読かどうか
  hasUnread() {
    return this.data.unlooked_count > 0;
  }

  // メンションのみ未読かどうか
  hasUnreadMention() {
    return this.data.unlooked_mention_count > 0;
  }

  // 最後に見た時間を記録する
  look() {
    return app.api.child('me').child(this.data.workspace_ref.path).child('look').put({});
  }
});


/*
 * project class
 */
store.registerDocumentStoreClass('projects', class Project extends AlogDocumentStore {
  constructor(item) {
    super(item);
  }

  getWorkspace() {
    return this.parent.parent;
  }

  // プロジェクトに所属するユーザーの一覧を取得
  async fetchProjectUsers() {
    const projects_users = await this.parent.parent.collection('projects_users').where('project_id', '==', this.id).fetch({ relation: true });
    // ユーザーの配列のする
    return projects_users.map(projects_user => projects_user.relation.user);
  }

  // メンション用の配列(ユーザーやグループの配列)を取得
  async fetchMentions() {
    var workspace = this.getWorkspace();

    // プロジェクトに所属するユーザーとワークスペースに所蔵するグループを取得
    var promises = [
      workspace.collection('projects_users').where('project_id', '==', this.id).fetch({ relation: true }),
      workspace.collection('groups').fetch(),
    ];
    var [projects_users, groups] = await Promise.all(promises);

    // とりあえず、動くようにデータ整形する。必要あればリファクタする
    // type: 'user' の配列を生成
    var mentions = projects_users.map(pu => {
      return {
        type: 'user',
        item: pu.relation.user,
      }
    });

    // type: 'group' の配列を生成
    groups.forEach(g => {
      mentions.push({
        type: 'group',
        item: g,
      });
    });
    return mentions;
  }

  // ステータスを取得する
  fetchColumns() {
    return this.collection('columns').orderBy('sort_num').fetch();
  }

  // プロジェクトを更新する
  update(data = {}) {
    return this.api.put(data);
  }

  // テンプレートノート一覧を取得
  fetchTemplates() {
    return this.collection('notes')
      .where('disabled', '==', false)
      .where('archived', '==', false)
      .where('tags', 'array-contains', 'template')
      .fetch();
  }

  // 一括既読
  lookAllNote() {
    return app.api.child('me').child(this.ref.path).child('look_all_note').put();
  }

  // アーカイブする
  archive() {
    return this.api.child('archive').put();
  }

  // アーカイブを解除する
  unarchive() {
    return this.api.child('unarchive').put();
  }

  // 色を取得
  getColor() {
    if (this.data.color) {
      return this.data.color;
    }
    else {
      let angle = app.utils.stringToColorAngle(this.data.name);
      return `hsl(${angle}, 55%, 55%)`;
    }
  }

  // カラムを追加する
  createColumn(name) {
    return this.api.child('columns').post({ name });
  }

  // title, content, column_id
  async createNote(param = {}) {
    var { note } = await app.api.child(this.ref.path).child('notes').post(param);
    return note;
  }

  // 自分自身が所属している project user を取得する(archive されたやつは見つからない)
  findProjectUser() {
    var projects_users = app.store.projectsUsersStore.items;
    return projects_users.find(pu => this.id === pu.data.project_id);
  }

  // プロジェクトの URL を取得
  getURL() {
    var project_user = this.findProjectUser();

    if (project_user) {
      // project_user がある場合は view も反映した状態の url を返す　
      return project_user.getProjectURL();
    }
    else {
      // archive された project の場合は普通に url を返す
      return `/${this.path}`;
    }
  }
  //- 複数ノートのカラムを変更
  changeNotesColumn({ note_ids, index, next_column_id }) {
    return this.api.child('columns/move_notes').put({
      note_ids,
      index,
      next_column_id,
    });
  }

  //- コメントに入力したメンションのユーザーを見つける
  async findMention(screen_name) {
    var mentions = await this.fetchMentions();
    var mention = mentions.find((mention) => {
      return mention.item.data.screen_name === screen_name;
    });
    return mention;
  }
});

/*
 * project_users class
 */
store.registerDocumentStoreClass('projects_users', class ProjectsUsers extends AlogDocumentStore {
  constructor(item) {
    super(item);
  }

  getProject() {
    return app.store.doc(this.data.project_ref.path);
  }

  getProjectURL(type) {
    // リスト表示のときのみ最後にアクセスしたノートに遷移させる
    return `/${this.data.project_ref.path}?view=${type || this.data.last_looked_view_name || "list"}`;
  }

  getInboxes({ looked } = {}) {
    // project check
    var inboxes = app.store.inboxesStore.items.filter(item => {
      return item.data.project_id === this.data.project_id;
    });

    if (looked !== undefined) {
      inboxes = inboxes.filter(inbox => {
        return inbox.data.looked === looked;
      });
    }

    return inboxes;
  }
});



/*
 * users class
 */
store.registerDocumentStoreClass('users', class User extends AlogDocumentStore {
  constructor(item) {
    super(item);
  }

  isMine() {
    return this.id === firebase.auth().currentUser.uid;
  }

});

/*
 * note class
 */
store.registerDocumentStoreClass('notes', class Note extends AlogDocumentStore {
  constructor(args) {
    super(args);
  }

  // お気に入りされているかを判定
  isFavorite() {
    // store のお気に入りリストに note が含まれていれば true
    return !!app.store.favorites.items.find(item => this.id === item.data.note_id);
  }

  // end_atが設定されているか
  existsEndAt() {
    return this.data.end_at;
  }
  // 誰かアサインされているかを判定
  isAssigned() {
    return this.data.assigned_user_refs.length > 0;
  }

  // 自分がアサインされているかを判定
  isAssignedMe() {
    var user_id = firebase.auth().currentUser.uid;
    return this.isAssignedByUserId(user_id);
  }

  // 指定した user_id のユーザーがアサインされているかを判定
  isAssignedByUserId(user_id) {
    return this.data.assigned_user_refs.some(ref => ref.id === user_id);
  }

  // 既読かどうかを判定
  isRead() {
    var inbox = this.getInbox();

    // inbox がない場合は既読とする
    if (!inbox) return true;

    return inbox.data.looked;
  }

  // お気に入りを toggle
  toggleFavorite() {
    var ref = app.api.child('me').child(this.path);
    if (this.isFavorite()) {
      return ref.child('unfavorite').put();
    }
    else {
      return ref.child('favorite').put();
    }
  }

  // ピン留めされているかを判定
  isPinned() {
    return this.data.pinned;
  }

  // ピン留めを toggle
  togglePin() {
    var ref = this.api;
    if (this.isPinned()) {
      return ref.child('unpin').put();
    }
    else {
      return ref.child('pin').put();
    }
  }

  getProject() {
    return this.parent.parent;
  }

  getWorkspace() {
    return this.getProject().getWorkspace();
  }

  getInbox() {
    if (!app.store.inboxesStore) return;
    // project check
    var inbox = app.store.inboxesStore.items.find(item => {
      return item.data.note_id === this.id;
    });

    return inbox;
  }

  // getInbox より確実に取得するバージョン
  async fetchInbox() {
    let inbox = this.getInbox();
    if (inbox) return inbox;
    [inbox] = await app.store.workspace_user.collection('inboxes').where('note_ref', '==', this._ref).fetch();
    return inbox;
  }

  //- ノートのtitleを取得
  getTitle() {
    //- タイトルがない時
    if (!this.data.title) {
      return "Untitled";
    }
    else {
      return this.data.title;
    }
  }

  // メンションを持っているかを判定
  hasMention() {
    var inbox = this.getInbox();

    if (!inbox) return false;

    return inbox.isMention();
  }

  // 既読にする
  look() {
    return app.api.child('me').child(this.path).child('look').put();
  }

  // アサイン
  async assign(assign_user_ids) {
    await this.api.put({
      assign_user_ids,
    });
    await this.refresh();
  }

  // アサインされているユーザーの一覧を取得
  async fetchAssignedUsers() {
    if (!this.data.assigned_user_refs || this.data.assigned_user_refs.length <= 0) {
      return [];
    }

    var promises = this.data.assigned_user_refs.map(item => app.store.doc(item.path).fetch());
    const users = await Promise.all(promises);

    return users;
  }
  fetchColumn() {
    if (this.data.column_ref) {
      return this.getProject().collection('columns').doc(this.data.column_ref.id).fetch();
    }
    return null;
  }

  // 期限を設定する
  updatePeriod({ start_at, end_at }) {
    return this.api.child('period').put({
      start_at,
      end_at,
    });
  }

  // 削除
  delete() {
    return this.api.del();
  }

  // リマインド更新
  remindVerify() {
    return app.api.child('me').child(this.path).child('remind_verify').put();
  }

  // 履歴を取得
  fetchHistories() {
    return this.collection('histories').orderBy('updated_at', 'desc').fetch({ relation: true });
  }

  // 複製
  clone() {
    return app.api.child(this.path).child('clone').post({});
  }

  // カラムを移動
  changeColumn({ column_id }) {
    return this.getProject().changeNotesColumn({
      note_ids: [this.id],
      next_column_id: column_id,
    });
  }

  // 次のカラムに移動
  async changeColumnToNext() {
    var project = this.getProject();
    var columns = await project.fetchColumns();
    var index = columns.findIndex((item) => item.id === this.data.column_ref.id)
    var next_column = columns[index + 1];
    if (next_column) {
      await this.changeColumn({ column_id: next_column.id });
    }
  }

  // note の期間のカラーを取得
  getTermColor() {
    // archive(close)されているときは非アクティブ色
    if (this.data.archived) {
      return 'text-label_dark_medium';
    }

    // archive されていないときは utils の関数を使う
    return app.utils.getTermColor(this.data.end_at);
  }
});


/*
 * message class
 */
store.registerDocumentStoreClass('messages', class Message extends AlogDocumentStore {
  constructor(item) {
    super(item);
  }

  // ユーザーを取得
  getUser() {
    return this.relation.user;
  }

  // アクティビティかどうかを判定
  isActivity() {
    return this.data.kind === 'activity';
  }
  // ノートを取得
  getNote() {
    return this.parent.parent;
  }

  // 自分のコメントかどうか判定
  isMine() {
    return this.data.user_id === firebase.auth().currentUser.uid;
  }

  isMentionToMe() {
    var mention_to_me = `@${app.store.currentUser.data.screen_name}`;
    return this.data.mentions.some(mention => mention === mention_to_me);
  }

  // コメントをupdateする
  updateContent(content) {
    return this.api.put({ content });
  }

  // コメントの削除
  deleteMessage() {
    return this.api.del();
  }

  // リアクション種類ごとのユーザー一覧を取得
  async getUsersByReactions() {
    var promises = this.data.reactions.map(async reaction => {
      reaction.user = await app.store.doc(reaction.user_ref.path).fetch();
    });
    await Promise.all(promises);
    var reactions = this.getReactions();
    // 未リアクションのプロジェクトユーザーを取得して追加する
    var project_users = await this.getNote().getProject().fetchProjectUsers();
    var no_reaction_users = project_users.filter(user => {
      return !this.data.reactions.some(reaction => reaction.user_ref.id === user.id);
    });
    reactions.no_reaction = no_reaction_users.map(user => {
      return {
        key: 'no_reaction',
        user: user,
        user_ref: user.ref,
      };
    });

    return reactions;
  }

  getReactions() {
    return _.groupBy(this.data.reactions, reaction => {
      return reaction.key;
    });
  }

  // すでにリアクションしているユーザーを取得
  getReaction(key, id) {
    var reaction = this.data.reactions.find(i => {
      return i.key === key && i.user_ref.id === id;
    })
    return reaction;
  }

  // リアクションの追加
  async addReaction(key, verify = true) {
    capacitor.hapticsImpactLight();

    var uid = firebase.auth().currentUser.uid;
    var user_ref = app.store.collection('users').doc(uid).ref;
    // すでにリアクションしているユーザーを取得
    var reaction = this.getReaction(key, user_ref.id);
    if (reaction) return;

    var data = {
      key: key,
      user_ref: user_ref,
      created_at: Date.now(),
    };
    await this.ref.update({
      reactions: firebase.firestore.FieldValue.arrayUnion(data),
    });

    if (verify) {
      // コメントの所属するノートに紐づくタスクを更新
      return this.getNote().remindVerify();
    }
  }

  // リアクションの削除
  removeReaction(key) {
    capacitor.hapticsImpactLight();

    var uid = firebase.auth().currentUser.uid;
    var user_ref = app.store.collection('users').doc(uid).ref;

    // 削除時にマッチングさせるユーザーを取得
    var reaction = this.getReaction(key, user_ref.id);

    return this.ref.update({
      reactions: firebase.firestore.FieldValue.arrayRemove(reaction),
    });
  }

  // リアクションをトグル
  toggleReaction(key) {
    return this.isMyReaction(key) ? this.removeReaction(key) : this.addReaction(key);
  }

  // 自分がリアクション済みかどうかを判定
  isMyReaction(key) {
    return this.data.reactions.some(reaction => {
      return key === reaction.key && reaction.user_ref.id === firebase.auth().currentUser.uid;
    });
  }

  hasMyReaction() {
    return this.data.reactions.some(reaction => {
      return reaction.user_ref.id === firebase.auth().currentUser.uid;
    });
  }

  //- 編集済みかを確認
  isEdited() {
    return this.data.updated_at > this.data.created_at;
  }

  favorite() {
    return this.api.child('favorite').put();
  }

  unfavorite() {
    return this.api.child('unfavorite').put();
  }

  remind() {
    return this.api.child('remind').put();
  }

  async isLastAsync() {
    const note = this.getNote();
    await note.fetch({ cache: false });
    if (!this.data) await this.fetch();
    return note.data && note.data.last_commented_at <= this.data.created_at;
  }

  // 最後のメッセージなら note を look する
  async look() {
    if (await this.isLastAsync()) {
      return this.getNote().look();
    }
  }
});


/*
 * inbox class
 */
store.registerDocumentStoreClass('inboxes', class Inbox extends AlogDocumentStore {
  constructor(item) {
    super(item);
  }

  isMention() {
    const mention_kinds = ["mention", "all_mention", "group_mention"];
    return mention_kinds.indexOf(this.data.looked_kind) !== -1;
  }
});

/*
 * column class
 */
store.registerDocumentStoreClass('columns', class Column extends AlogDocumentStore {
  constructor(item) {
    super(item);
  }

  // カラムを更新する
  update({ name, sort_num }) {
    return this.api.put({
      name,
      sort_num
    });
  }

  // カラムを削除する
  delete() {
    return this.api.del();
  }

  getProject() {
    return this.parent.parent;
  }

});



/*
 * remind class
 */
store.registerDocumentStoreClass('reminds', class Remind extends AlogDocumentStore {
  constructor(item) {
    super(item);
  }

  // ノートを取得
  getNote() {
    return this.relation.note;
  }

  // 完了
  done() {
    if (this.data.done) return;
    const workspace = this.getNote().getWorkspace();
    return app.api.child(`/me/workspaces/${workspace.id}/reminds/${this.id}/done`).put();
  }

  // 未完了
  restore() {
    if (!this.data.done) return;
    //- コメントをリマインドに追加するAPIを叩く
    return this.relation.message.remind();
  }

  // このリマインドに紐づくメッセージだけが未読の場合だけ、既読APIを叩く
  async look() {
    const { message } = this.relation;
    if (!message) return;

    //- 最後のコメントじゃない場合は既読にしない
    if (!(await message.isLastAsync())) return;
    const note = message.getNote();
    const inbox = await note.fetchInbox();
    if (!inbox) return;

    //- 最後に既読にした時間よりあとのメッセージを32件取得
    let result_messages = await note.collection('messages')
      .where('disabled', '==', false)
      //- アクティビティ以外の実際のコメントだけ
      .where('kind', '==', 'comment')
      //- 自分以外
      .orderBy('created_at', 'asc')
      .where('created_at', '>', inbox.data.last_message_read_at)
      .limit(32)
      .fetch();

    //- 自分のを除外した最初の一件を取得
    const [result_message] = result_messages.filter((message) => message.data.user_id !== app.store.currentUser.id);

    //- このメッセージと同じ場合
    if (result_message && result_message.path === message.path) {
      //- ノートを既読にする
      await note.look();
    }
  }

  async addReaction(key) {
    var message = this.relation.message;
    if (message.isMyReaction(key)) return;
    await message.addReaction(key, false);
    // reaction の状態を最新にする
    message.refresh();
  }

});


export default store;
