const riot = require('riot');

riot.tag2('atom-assign', '<div class="f fm cursor-pointer" ref="button" onclick="{onOpenPopup}" ondblclick="{onAssignMe}"> <div class="f fh circle s20 bg-background_light_primary" if="{!assigned_users.length}"><i class="icon-assign text-label_dark_medium fs12 tooltip" aria-label="{ariaLabel}" data-tooltip-position="top"></i></div> <div class="f" if="{assigned_users.length}"> <div class="f fh bg-white circle s22 flex-fixed mr0-last mrn6 tooltip" each="{user in assigned_users.slice(0, 3)}" aria-label="{user.data.display_name}" data-tooltip-position="top"><img class="s-full circle object-fit-cover border border-white" riot-src="{app.utils.getImageUrl(user.data.icon_image)}"></div> <div class="rounded-full bg-separator_opaque f fh s20 text-label_dark_medium mr4 mr0-last" if="{assigned_users.length &gt; 3}"><i class="icon-more_vertical fs12 tooltip" aria-label="他{assigned_users.length - 3}名" data-tooltip-position="top"></i></div> </div> </div>', 'atom-assign,[data-is="atom-assign"]{display:block}', '', function(opts) {
    this.on('mount', () => {
      this.setup();
    });
    this.on('update', () => {
      this.setup();
    });

    this.ariaLabel = 'アサインユーザーを編集\n ダブルクリックで自分をアサイン';

    this.setup = async () => {

      if (this.opts.note && this.opts.note.data && this._last_updated_at !== this.opts.note.data.updated_at) {
        this._last_updated_at = this.opts.note.data.updated_at;
        this.assigned_users = await this.opts.note.fetchAssignedUsers();
        this.update();
      }
    };

    this.onOpenPopup = async (e) => {
      e.preventDefault();
      e.stopPropagation();

      var project_users = await this.opts.note.parent.parent.fetchProjectUsers();

      let selected = false;

      const popup = spat.popup.open('popup-suggestion-users', {
        element: this.root,
        closeTarget: e.currentTarget,
        offsetLeft: 100,
        offsetTop: 16,
        position: 'bottom',
        opts: {
          label: 'アサインユーザーを編集',
          users: project_users,
          selectedUsersIds: this.assigned_users.map(u => u.id),
        },
      });

      popup.on('userselect', async ({ user, checked }) => {
        selected = true;

        app.utils.openToast(checked ? 'assignedUpdate' : 'unassignedUpdate', {label: user.data.screen_name});
        this.update();
      });

      popup.on('close', async () => {
        let selected_user_ids = popup.getSelectedUsers().map(user => user.id);

        if (!selected) return;

        try {
          await this.opts.note.assign(selected_user_ids);
        }
        catch (err) {
          console.error(err);
          app.utils.openToast('error', { label: 'アサインに失敗しました' });
        }
        this.update();
      });
    };

    this.click = () => {
      this.refs.button.click();
    };

    this.onAssignMe = async () => {

      if (this.assigned_users.find(u => u.id === app.store.currentUser.id)) return;
      try {

        await this.opts.note.assign([...this.assigned_users.map(u => u.id) ,app.store.currentUser.id]);
        app.utils.openToast('assignedUpdate', { label: app.store.currentUser.data.screen_name });
        this.update();
      }
      catch (e) {
        console.error(e);
        app.utils.openToast('error', { label: 'アサインに失敗しました' });
      }
    };
});