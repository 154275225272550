const riot = require('riot');

riot.tag2('spat-nav', '<div class="h-full" ref="pages"></div>', 'spat-nav,[data-is="spat-nav"]{display:block;height:100%} spat-nav .spat-page,[data-is="spat-nav"] .spat-page{height:100%}', '', function(opts) {
    this.cachedPageTags = {};

    this.goto = async ({route, req, res, ssr=true, isPageChanging=false}) => {
      var tagName = '';
      var cached = false;

      if (typeof route.tag === 'function') {
        tagName = await route.tag({req, res});
      }
      else {
        tagName = route.tag;
      }

      if (!isPageChanging) {
        this.trigger('pagechange');
      }

      var tempPageTag = this.currentPageTag;

      if (spat.isBrowser && tempPageTag) {
        tempPageTag.trigger('hide', {
          req, res
        });
        tempPageTag.root.style.display = 'none';
      }

      var cacheKey = null;

      if (!res.error) {

        cacheKey = route.cacheKey ? route.cacheKey({route, req, res}) : req.Url.pathname;
      }
      var currentPageTag = cacheKey ? this.getCachedPageTag(cacheKey) : null;

      if (currentPageTag) {
        currentPageTag.root.style.display = '';
        cached = true;
      }
      else {
        var element = document.createElement('div');
        element.setAttribute('data-is', tagName);
        element.setAttribute('class', 'spat-page');
        element.setAttribute('cache-key', cacheKey);
        currentPageTag = riot.mount(element, tagName)[0];
        this.refs.pages.appendChild(element);
      }

      this.currentPageTag = currentPageTag;

      try {

        if (ssr) {
          await this.preload(currentPageTag, {
            req, res, cached,
          });

          if (res.statusCode === 301 || res.statusCode === 302) {
            currentPageTag.root.style.display = 'none';
            return ;
          }
        }

        if (this.currentPageTag === currentPageTag) {

          this.trigger('pagechanged', {});

          if (spat.isBrowser && !res.error) {
            this.setCachedPageTag(this.currentPageTag);
          }
        }

        else {

          currentPageTag.root.style.display = 'none';
        }
      }
      catch(e) {

        currentPageTag.root.style.display = 'none';

        console.error(e);

        res.error = e;

        var errorRoute = {
          tag: 'page-error'
        };
        await this.goto({route: errorRoute, req, res, isPageChanging: true});
      }

      if (spat.isBrowser && this.currentPageTag === currentPageTag) {
        this.currentPageTag.trigger('show', {
          req, res, cached
        });
        this.currentPageTag.update();
      }

      return {
        cached,
      };
    };

    this.setCachedPageTag = (tag) => {
      if (tag.opts.cacheKey) {
        this.cachedPageTags[tag.opts.cacheKey] = tag;
      }
    };

    this.getCachedPageTag = (cacheKey) => {
      return this.cachedPageTags[cacheKey];
    };

    this.preload = async (tag, opts) => {
      if (tag.preload) {
        var data = await tag.preload(opts);
        Object.assign(tag, data);
        tag.update();
      }

      var tags = _.flatten(Object.values(tag.tags));

      if (tags.length <= 0) return ;

      var promises = tags.map(async (tag) => {
        return this.preload(tag, opts);
      });

      await Promise.all(promises);
    };

    this.getHead = () => {
      var tag = this.currentPageTag;
      if (!tag) return spat.config.head;

      var head = {};
      spat.utils.extendDeep(head, spat.config.head);
      if (tag.head) {
        var data = tag.head();
        spat.utils.extendDeep(head, data);
      }

      return head;
    };

    this.on('before-unmount', () => {

      if (spat.isNode) {
        let nodes = [];
        for (let i = 0, node; node = this.refs.pages.childNodes.item(i); ++i) {
          nodes.push(node);
        }
        nodes.forEach(node => {
          if (node._tag) {
            node._tag.unmount();
          }
        });
      }
      else {
        [...this.refs.pages.children].forEach(element => {
          if (element._tag) {
            element._tag.unmount();
          }
        });
      }
    });
});