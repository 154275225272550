const riot = require('riot');

riot.tag2('page-settings', '<div class="h-full f fclm"> <div data-is="module-header" title="マイページ" back="{true}"></div> <div class="s-full px16 mb16"> <div class="s-full f border border-separator_opacity lh15 rounded-12 overflow-hidden"> <div class="bg-background_light_secondary h-full max-width-248 w-full pt16"> <div class="pl8 border-left border-label_dark_medium bw3 fs11 text-label_dark_medium bold my8">{app.data.settings.user.title}</div> <div class="mb32"> <div class="py14 px16 cursor-pointer {tab.id === current ? \'bg-white bold border-bottom border-transparent\' : \'border-bottom\'}" each="{tab in app.data.settings.user.tabs}" onclick="{onChangeTab}"> <div class="fs14 lh13">{tab.text}</div> </div> </div> <div class="f fc"> <button class="button dark_light h40 w113 transition" onclick="{onSignOut}">ログアウト</button> </div> </div> <div class="w-full bg-white scrollbar-none py24"> <div class="max-width-576 w-full mxa"> <div data-is="module-{current}_"></div> </div> </div> </div> </div> </div>', 'page-settings,[data-is="page-settings"]{display:block}', '', function(opts) {
    this.on('show', ({req, res}) => {

      this.current = req.params.tab || 'profile';
      this.update();
    });

    this.onChangeTab = (e) => {
      this.current = e.item.tab.id;

      spat.router.replace(`/settings/${this.current}`);
      this.update();
    };

    this.onSignOut = async() => {
      var result = await spat.modal.confirm('ログアウトしますか?');
      if (!result) return;
      app.auth.signOut();
      spat.router.push('/signin');
    };

});
riot.tag2('module-profile_', '<div class="fs16 bold lh15 mb32">プロフィール</div> <form class="mb36" onsubmit="{submit}"> <div class="s80 circle overflow-hidden mb21" ref="icon" data-is="atom-input-image" riot-src="{app.store.currentUser.data.icon_image.url}" icon-size="48"></div> <div class="mb24"> <label> <div class="mb12"> <div class="fs10 lh13 mb6">表示名</div> <input class="input large light_secondary w-full" ref="name" placeholder="{app.data.input.placeholder.display_name}" required> </div> </label> <label> <div class="lh15 mb12"> <div class="f fm lh13 fs10 mb6">ユーザー名</div> <label class="focus-border input large light_secondary f fm"> <div>@</div> <input class="w-full" ref="id" placeholder="{app.data.input.placeholder.id}" minlength="3" maxlength="21" required> </label> <div class="fs10 text-label_dark_medium"> 先頭の「@」を除く、小文字の半角英語文字と数字3文字以上20文字以下、-と_のみ使用可能。</div> </div> </label> </div> <button class="button w220 primary transition">更新</button> </form> <div class="mb36"> <div class="fs14 lh13 fw7 mb16">メールアドレス</div> <p class="mb16">あなたのメールアドレスは <b>{app.auth.currentUser.email} </b>です。</p> <button class="button primary_fill f fh py0 h32" onclick="{changeEmail}">メールアドレスを変更</button> </div> <div class="mb36"> <div class="fs14 lh13 fw7 mb16">パスワード</div> <button class="button primary_fill f fh py0 h32" onclick="{changePassword}">パスワードを変更</button> </div>', 'module-profile_,[data-is="module-profile_"]{display:block} module-profile_ input::placeholder,[data-is="module-profile_"] input::placeholder{color:rgba(29,40,76,0.2)} module-profile_ input:focus,[data-is="module-profile_"] input:focus{outline:none}', '', function(opts) {
    this.on('mount', () => {
      if (spat.isNode) return;

      var user = app.store.currentUser;

      this.refs.name.value = user.data.display_name;
      this.refs.id.value = user.data.screen_name;

      this.update();
    });

    this.submit = async (e) => {
      e.preventDefault();

      var data = {
        display_name: this.refs.name.value.trim(),
        screen_name: this.refs.id.value.trim(),
      };

      let screen_name_validator = app.data.input.validation.screen_name;

      if (!screen_name_validator.test(data.screen_name)) {
        spat.modal.alert('ユーザー名は小文字の英数字、- と _ のみ使用可能で、3～20文字以内で入力してください。');
        return;
      }

      var i = spat.modal.indicator();

      var url = await this.refs.icon.uploadAndGetImageURL();
      if (url) {
        data.icon_image = {
          url: url,
        };
      }

      await app.api.child('me').put(data);

      i.close();

      spat.modal.alert('完了しました！');
    };

    this.changeEmail = () => {
      spat.modal.open('modal-email');
    };

    this.changePassword = () => {
      spat.modal.open('modal-password');
    };

});
riot.tag2('module-workspaces_', '<div class="lh13 bold fs16 mb24">参加中のワークスペース一覧</div> <div class="h90vh border border-separator_opaque pb4 rounded-8 overflow-y-scroll"> <div class="border-bottom border-separator_opaque" data-is="item-workspace_" each="{item in observer.items}" item="{item}"></div> </div>', 'module-workspaces_,[data-is="module-workspaces_"]{display:block}', '', function(opts) {
    this.on('mount', async () => {
      this.store = app.store.collection(`workspaces_users`).where('user_id', '==', app.auth.currentUser.uid);

      this.observer = this.store.observer(() => {
        this.update();
        this.observer.items.forEach(async item => {
          await item.relate();
          app.utils.debouncedUpdate();
        });
      });

      await this.observer.observe();
    });

    this.on('unmount', () => {
      this.observer.unobserve();
    });

});
riot.tag2('item-workspace_', '<a class="relative block max-width-576 pl16 hover-trigger hover-bg-pale" href="/{opts.item.relation.workspace.path}/inboxes"> <div class="f fm fbw pr12 h56 cursor-pointer"> <div class="f fm mr6"><img class="s32 object-fit-cover rounded-8 mr12" riot-src="{app.utils.getImageUrl(opts.item.relation.workspace.data.icon_image)}"> <div class="bold line-clamp-1 word-break-all mr10">{opts.item.relation.workspace.data.name}</div> <div class="flex-fixed text-label_dark_medium" if="{opts.item.data.workspace_id === app.store.workspace_id}">(現在選択中のワークスペース)</div> </div><i class="icon-more_vertical fs16 p4 tooltip" onclick="{onOpenActionSheet}" aria-label="編集" data-tooltip-position="top"></i> </div></a>', 'item-workspace_,[data-is="item-workspace_"]{display:block}', '', function(opts) {

    this.onOpenActionSheet = (e) => {
      e.preventDefault();

      var options = [
        { label: 'このワークスペースにいく', icon: 'door', action: () =>{ popup.close(); this.moveWorkspace(); } },
        { label: '脱退', icon: 'cicle-arrow-left', action: () =>{ popup.close(); this.withdrawalWorkspace(); }, accent: true },
      ];
      if (this.opts.item.data.role !== "guest") {
        options.splice(1, 0, { label: 'ワークスペースを編集', icon: 'edit', action: () =>{ popup.close(); this.editWorkspace(); } });
      }
      const popup = spat.popup.open('popup-action-sheet', {
        element: e.currentTarget,
        closeTarget: e.currentTarget,
        offsetTop: 4,
        offsetLeft: 55,
        position: 'bottom',
        opts: {
          options: options,
        },
      });
    };

    this.moveWorkspace = () => {
      spat.router.push(`/${opts.item.relation.workspace.path}/inboxes`);
    };

    this.editWorkspace = () => {
      spat.router.push(`/${opts.item.relation.workspace.path}/settings`);
    };

    this.withdrawalWorkspace = async () => {
      spat.modal.alert('ワークスペースを脱退機能未実装');
    };

});
riot.tag2('module-setting_', '<div> <div class="fs16 bold lh15 mb25">設定</div> <div class="bold lh13 mb21">リスト表示</div> <div class="f fl fm mb36"> <div class="h106 border rounded-8 px12 pt10 pb12 hover-trigger hover-border-primary cursor-pointer mr24 {current === tab.id ? &quot;border-primary bg-pale&quot; : &quot;border-separator_opacity&quot;}" each="{tab in viewTabs}" onclick="{onSelectView}"> <div class="mb10"> <div class="text-center lh15 {&quot;text-primary&quot; : current === tab.id}">{tab.label}</div> </div> <div class="f fm h54"><img class="w-full object-fit-contain" riot-src="{tab.image}"></div> </div> </div> <div class="fs14 bold lh13 mb34">通知</div> <div class="f fm fbw border-bottom border-separator_opaque pr16 pb6"> <div class="fs14 lh15">デスクトップ通知を有効にする</div> <div data-is="atom-switch" ref="notification"></div> </div> </div>', 'module-setting_,[data-is="module-setting_"]{display:block}', '', function(opts) {
    this.viewTabs = [
      {
        id: 'default',
        label: '内容を全て表示(デフォルト)',
        image: '/images/settings/default.svg',
      },
      {
        id: 'compact',
        label: 'コンパクトに表示',
        image: '/images/settings/compact.svg',
      },
    ];

    this.on('mount', () => {

      this.current = 'default';
      this.refs.notification.on('toggleChecked', ({checked}) => {

      });
    });

    this.onSelectView = (e) => {
      if (this.current === e.item.tab.id) return;

      this.current = e.item.tab.id;
      this.update();
    };
});