const riot = require('riot');

riot.tag2('popup-message-actions', '<div class="f fm bg-white rounded-6 box-shadow-primary px2 cursor-pointer" ref="popup" onmouseleave="{close}"> <div class="f fm" data-is="module-emoji-mini" ref="emoji"></div> <div class="fs16 py4 px8 py2 s-fs20" data-is="atom-emoji" onselected="{onSelectReaction}" disabled-comment-popup="{true}"></div> <div data-is="module-action-menus" ref="menu" is-edit="{opts.isEdit}" is-mine="{opts.isMine}"></div> </div>', '', '', function(opts) {
    this.on('mount', () => {

      this.refs.emoji.on('selected', (emoji) => {
        this.trigger('selected', emoji);
      });

      this.refs.menu.on('replyClick', () => {
        this.trigger('replyClick');
      });

      this.refs.menu.on('editClick', () => {
        this.trigger('editClick');
      });

      this.refs.menu.on('menuClick', (e) => {
        this.trigger('menuClick', e);
      });
    });

    this.onSelectReaction = (emoji) => {
      this.opts.onEmojiSelected(emoji.name);
    };

    this.isOnMouse = (target) => {
      return this.refs.popup.contains(target);
    };
});