import app from './app';

const removeMd = require('remove-markdown')


var utils = {
  removeMd: removeMd,

  unique(arr, callback) {
    if (!callback) return [...new Set(arr)];
    const map = new Map();
    for (const obj of arr) map.set(callback(obj), obj);
    return [...map.values()];
  },

  wait(time) {
    return new Promise((resovle) => {
      setTimeout(resovle, time);
    });
  },

  toEditor(dom) {
    var editor = HyperMD.fromTextArea(dom, {
      lineNumbers: false,
      highlightFormatting: true,
      // readOnly: true,
      // autofocus: true, // ?
      // autoRefresh: true, // ?
    });
    return editor;
  },

  getTitle(content) {
    content = content.trim();

    // h1 を探す
    var match = content.match(/^#\s+[^\s].+$/mg);
    var title = match ? match[0] : '';

    // h1 がなければ先頭の行を取ってくる
    if (!title) {
      title = content.split('\n').find(t => (t.trim() !== '#' && t));
    }

    // プレーンテキストに変換
    title = removeMd(title);

    return title;
  },

  //- テンプレート中身を形成して取得
  getTemplateContent(note) {
    var d = dayjs().locale("ja");

    var data = {
      year: d.format('YYYY'),
      month: d.format('MM'),
      day: d.format('DD'),
      weekday: d.format('ddd'),

      hour: d.format('HH'),
      minutes: d.format('mm'),

      me: app.store.currentUser.data.screen_name,
    };
    var content = note.data.content;
    // タイトルを取り除く
    content = content.trim().split('\n').slice(1).join('\n');
    // データ置換
    content = content.replace(/\$\{([\S]+?)\}/g, (a, b, c) => {
      return data[b] || '';
    });
    // template タグを取り除く
    content = content.replace(/\#template/g, '');
    // trim
    content = content.trim();
    return content;
  },

  // ファイル呼び出し
  createImageFileInput(accept = '') {
    let fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    if (accept) {
      fileInput.accept = accept;
    }
    return fileInput;
  },

  getImage(content) {
    var result = content.match(/!\[.*\]\(.*\)|!\[.*\]\[.*\]|\[.*\]: .*"".*""/);
    if (result) {
      var link = result[0].match(/]\((.+)\)/);

      if (link && link[1]) {
        return link[1];
      }
    }
    return '';
  },

  debouncedUpdate: _.debounce(() => {
    // console.log('riot debounced update');
    riot.update()
  }, 100),

  uploadFile: async (file, is_sensitive = false) => {
    let download_url;
    if (is_sensitive) {
      download_url = await app.storage.uploadFile(file);
    }
    else {
      let { upload_url, download_url: _download_url } = await app.api.child('storages/content/generate_content_url').post({
        "content_type": file.type,
      });

      download_url = _download_url;

      await fetch(upload_url, {
        method: "PUT",
        headers: {
          "Content-Type": file.type
        },
        body: file
      });
    }

    return download_url;
  },


  /**
   * @param {URL} url 
   * @returns { RegExpMatchArray } 
   */
  matchNoteUrlPattern(url) {
    return url.pathname.match(/^\/workspaces\/([^\/]+)\/projects\/([^\/]+)\/notes\/([^\/]+)\/?$/) || [];
  },

  /** alogのノートURLかどうかをチェック */
  isNoteUrl(url) {
    return url.origin === location.origin && !!app.utils.matchNoteUrlPattern(url).length;
  },

  /**
   * markdown 内のリンクを開く
   * @param {URL} url 
   */
  openMarkdownLink: async (url) => {
    if (url.origin === location.origin) {
      if (app.useragent.isDesktop) {
        //- alogのノートURLかどうかをチェック
        let [matched, workspace_id, project_id, note_id] = app.utils.matchNoteUrlPattern(url);

        //- aタグのリンクがalogのノートURLだった場合は、モーダルで内容を表示する
        if (matched) {
          try {
            let ref = app.store.doc(`workspaces/${workspace_id}/projects/${project_id}/notes/${note_id}`);
            let note = await ref.fetch();
            spat.modal.open('modal-note', {
              note,
            });
          }
          catch (err) {
            console.error(err.message);
            spat.modal.alert(err.message);
          }
          return;
        }
      }
      //- ノートURL以外はページ内遷移
      spat.router.push(url.href);
    }
    else {
      app.utils.openLink(url.href);
    }
  },

  // web だったら window.open, native だったら in app browser で開く
  openLink(url) {
    if (capacitor.isAvailable) {
      capacitor.openInBrowser(url);
    }
    else {
      window.open(url);
    }
  },

  stringToColorAngle: (str) => {
    return [...str].reduce((sum, ch) => {
      var code = Number(ch.charCodeAt());
      return Number(sum) + code * code;
    }, 0) % 360;
  },

  getProjectColor(project) {
    if (project.data.color) return project.data.color;
    var hsl_value = this.stringToColorAngle(project.data.name);
    return `hsl(${hsl_value}, 60%, 60%)`;
  },

  // 画像のサイズを最適化する、sizeがない場合は、オリジナルurlを表示する
  getImageUrl: (image, sizeKey = 'thumbnail') => {
    return image.sizes ? image.sizes[sizeKey].url : image.url;

  },
  // 正規表現周り
  regexp: {
    escape(str) {
      return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    },
    // switcher 用
    createFuzzySearch(str, option) {
      var escapeTarget = /[-\/\\^$*+?.()|[\]{}]/;
      var res = '';
      for (var i = 0; i < str.length; i++) {
        var s = str[i];
        if (escapeTarget.test(s)) {
          res += `\\${s}`;
        }
        else {
          res += s;
        }
        if (i < str.length - 1) {
          res += '.*';
        }
      }
      return new RegExp(res, option);
    },
  },
  // 検索対象(users)と検索ワード(value)を渡す
  filterUsers: (users, value, getUserFunc = (u) => u) => {
    var regexp = app.utils.regexp.createFuzzySearch(value);
    //- 前方一致しなくなった場合は、含まれているかどうかも探す
    var users = users.filter(user => {
      const { screen_name, display_name } = getUserFunc(user).data;
      if (screen_name.includes(value) || display_name.includes(value)) {
        return true;
      }
      else {
        return regexp.test(screen_name) || regexp.test(display_name);
      }
    });
    users = app.utils.displayUsersSort(users, getUserFunc);
    return users;
  },
  // 検索後のソート
  displayUsersSort: (users, getUserFunc = (u) => u) => {
    users.sort((a, b) => {
      a = getUserFunc(a);
      b = getUserFunc(b);

      if (a.id === app.auth.currentUser.uid) {
        return -1;
      }
      else if (b.id === app.auth.currentUser.uid) {
        return 1;
      }
      else {
        return a.data.screen_name > b.data.screen_name ? 1 : -1;
      }
    });
    return users;
  },
  normalizeKeyOrCallback: (key_or_callback) => {
    if (!key_or_callback) {
      key_or_callback = (item) => item;
    }
    if (typeof key_or_callback !== 'function') {
      var key = key_or_callback + '';
      key_or_callback = (item) => {
        return key.split('.').reduce((item, key) => item[key] || '', item);
      };
    }
    return key_or_callback;
  },
  /**
   * 一致度が高い順に複数の配列を配列で返します
   * callback は string を返すようにしてください
   **/
  filterItems: (items, word, key_or_callback) => {
    if (!word) return [items];
    key_or_callback = utils.normalizeKeyOrCallback(key_or_callback);
    var wordLowerCase = word.toLowerCase();
    // 文字列マッチ用の正規表現を生成
    var reg = utils.regexp.createFuzzySearch(word, 'i');
    var partMatchItems = [];
    var fuzzyMatchItems = [];
    items.forEach(item => {
      var text = key_or_callback(item) || '';
      var index = text.toLowerCase().indexOf(wordLowerCase);
      // 部分一致
      if (index !== -1) {
        partMatchItems.push({
          item,
          index,
          length: text.length,
        });
      }
      else {
        var textTest = reg.test(text);
        // 曖昧一致
        if (textTest) {
          fuzzyMatchItems.push(item);
        }
      }
    });
    return [
      partMatchItems.sort((a, b) => {
        if (a.index === b.index) {
          return a.length - b.length;
        }
        else {
          return a.index - b.index;
        }
      }).map(item => item.item),
      fuzzyMatchItems,
    ];
  },

  fuzzySearchItems: ({ value, items, key_or_callback }) => {
    key_or_callback = utils.normalizeKeyOrCallback(key_or_callback);
    if (value) {
      var result = [];
      // 空白文字で分割して AND 検索。結果の配列の配列を result に flatten で入れる
      value.split(/\s+/).reduce((filteredItems, word) => {
        var results = [];
        // 一致度が高い順に並ぶようにpushする
        filteredItems.forEach((items) => {
          utils.filterItems(items, word, key_or_callback).forEach(items => {
            if (items.length > 0) {
              results.push(items);
            }
          });
        });
        return results;
      }, [items]).forEach(items => items.forEach(item => result.push(item)));
      // index を持つオブジェクトに変換
      return result;
    }
    else {
      return items;
    }
  },
  openAssignModal(note) {
    var modal = spat.modal.open('modal-assign-user', { note });
    modal.focusInput();
    return modal;
  },
  copyToClipBoard(text) {
    return navigator.clipboard.writeText(text);
  },

  //- タイトルとリンクをマークダウン形式で取得
  getInsertLinkToTitle({ url, title, bold = false }) {
    title = title || url;
    //- サニタイズ
    title = title.replace(/(\[|\]|\*|\\)/g, '\\$1');
    url = url.replace(/(\(|\)|\\)/g, '\\$1');
    if (bold) {
      title = `**${title}**`;
    }
    return `[${title}](${url})`;
  },

  // プロジェクト名を取得
  getProjectName: (project_name) => {
    var names = project_name.split('/');

    // ディレクトリが指定されていれば後ろの値を, 指定されていなければそのままの値
    return names.length >= 2 ? names[1] : names[0];
  },

  // ノートをフィルタする
  filterNotes: (notes, query) => {
    // テキスト
    if (query.text) {
      var text = query.text.toLowerCase();
      notes = notes.filter(note => {
        // 小文字同士で判定する
        return note.data.title.toLowerCase().indexOf(text) !== -1;
      });
    }
    // タグ
    if (query.tags.length) {
      notes = notes.filter(note => {
        if (!note.data.expanded_tags || note.data.expanded_tags.length <= 0) return false;

        // どれかのタグにヒットしたら true
        var hit = query.tags.some(tag => {
          return note.data.expanded_tags.includes(tag);
        });

        return hit;
      });
    }

    // アサイン
    if (query.options.assigned_id) {
      notes = notes.filter(note => note.isAssignedByUserId(query.options.assigned_id));
    }

    // 作成者
    if (query.options.created_id) {
      notes = notes.filter(note => note.data.created_user_ref.id === query.options.created_id);
    }

    // 更新者
    if (query.options.updated_id) {
      notes = notes.filter(note => note.data.updated_user_ref.id === query.options.updated_id);
    }

    // カラム
    if (query.options.column) {
      notes = notes.filter(note => note.data.column_ref.id === query.options.column_id);
    }

    // 未読
    if (query.options.unread) {
      notes = notes.filter(note => !note.isRead());
    }

    // メンション
    if (query.options.mention) {
      notes = notes.filter(note => note.hasMention());
    }

    // お気に入り
    if (query.options.favorite) {
      notes = notes.filter(note => note.isFavorite());
    }

    // 未アサイン
    if (query.options.unassigned) {
      notes = notes.filter(note => !note.isAssigned());
    }

    // 期限あり
    if (query.options.period) {
      notes = notes.filter(note => note.existsEndAt());
    }

    // 今日更新があったもの
    if (query.options.updatedToday) {
      notes = notes.filter(note => dayjs(note.data.updated_at).isSame(dayjs(), 'day'));
    }

    return notes;
  },

  // 渡された elements で split
  split(elements, { storageKey, defaultSizes, direction, minSizes, maxSizes, onDrag }) {
    var sizes = localStorage.getItem(storageKey);
    sizes = sizes ? JSON.parse(sizes) : defaultSizes;
    var split = Split(elements, {
      sizes: sizes,
      gutterSize: 4,
      direction: direction,
      onDrag,
      onDragEnd: (sizes) => {
        var sizes_str = JSON.stringify(sizes);
        localStorage.setItem(storageKey, sizes_str);
      }
    });

    // minSizes
    if (minSizes) {
      minSizes.forEach((s, i) => {
        if (s) {
          elements[i].style.minWidth = s + 'px';
        }
      });
    }
    // maxSizes
    if (maxSizes) {
      maxSizes.forEach((s, i) => {
        if (s) {
          elements[i].style.maxWidth = s + 'px';
        }
      });
    }

    return split;
  },

  roles: ["admin", "member", "guest"],

  getRoleClass(role) {
    return {
      'admin': 'bg-background_dark_primary',
      'member': 'bg-label_dark_medium',
      'guest': 'bg-label_dark_medium',
      'bot': 'bg-label_dark_medium',
    }[role];
  },

  // 詳細情報を表示するときはtextに入れる
  openToast(type, options = {}) {
    var timeout = options.timeout || 10000;
    var toast_type = {
      // 削除
      delete: {
        title: '削除しました。',
        icon: 'stash_box',
      },
      deleteComment: {
        title: 'コメントを削除しました。',
        icon: 'stash_box',
      },
      deleteUser: {
        title: 'ユーザーを削除しました。',
        icon: 'stash_box',
      },
      deleteGroup: {
        title: `@${options.label}を削除しました。`,
        icon: 'stash_box',
      },
      // 更新
      update: {
        title: '更新しました。',
        icon: 'reload',
      },
      // 期間
      setPeriod: {
        title: '期限を設定しました。',
        icon: 'time',
      },
      clearPeriod: {
        title: '期限をクリアしました。',
        icon: 'time',
      },
      // コピー
      copy: {
        title: 'コピーしました。',
        icon: '',
      },
      // 複製
      clone: {
        title: '複製しました。',
        icon: '',
      },
      // ワークスペース
      createWorkspace: {
        title: 'ワークスペースを作成しました',
        icon: 'add',
      },
      // ワークスペース
      deleteWorkspace: {
        title: 'ワークスペースを削除しました',
        icon: 'stash_box',
      },
      // プロジェクト
      createProject: {
        title: 'プロジェクトを作成しました',
        icon: 'add',
      },
      // プロジェクト
      createDM: {
        title: 'ダイレクトメッセージを作成しました',
        icon: 'add',
      },
      archiveProject: {
        title: 'プロジェクトをアーカイブしました。',
        icon: 'archive',
      },
      releaseProject: {
        title: 'プロジェクトのアーカイブを解除しました。',
        icon: 'archive',
      },
      // 招待
      inviteMail: {
        title: 'ユーザーに招待メールを送りました。',
        icon: '',
      },
      resendInviteMail: {
        title: `${options.label}にメールを再送信しました。`,
        icon: '',
      },
      cancelInvitation: {
        title: '招待を取り消しました。',
        icon: '',
      },
      resendInvitation: {
        title: '再送信しました。',
        icon: 'send',
      },
      updatePassword: {
        title: 'パスワードを更新しました',
        icon: '',
      },
      // 追加
      add: {
        title: '追加しました',
        icon: '',
      },
      // 既読
      allRead: {
        title: '全て既読にしました。',
        icon: 'open_mail',
      },
      // アサインユーザーのアップデート
      assignedUpdate: {
        title: `${options.label}にアサインしました。`,
        icon: 'assign',
      },
      // アサインユーザーのアップデート
      unassignedUpdate: {
        title: `${options.label}をアサインから外しました`,
        icon: 'assign',
      },
    }[type];
    spat.toast.message(toast_type.title, { icon: toast_type.icon, timeout: timeout });
  },

  // 期間の色を返す(期限との差に応じて表示色を変える)
  getTermColor(end_at) {
    var now = dayjs();
    var end = dayjs(end_at);

    if (end.isBefore(now, 'd')) {
      // タイムオーバー(end_at よりも今が後の場合)は強めの警告
      return 'text-alert_primary';
    }
    else if (end.subtract(2, 'd').isBefore(now, 'd')) {
      // 期限が直近だと弱めの警告色
      return 'text-orange';
    }

    return 'text-term';
  },
  _hasInputFocus: false,
  // ソフトウェアキーボードが出てるかどうか監視
  setupInputFocusChange() {
    window.addEventListener('focusin', e => {
      if (!this._hasInputFocus) {
        this._hasInputFocus = /^(input|textarea|select)$/i.test(document.activeElement.tagName) || document.activeElement.closest('[contenteditable="true"]');
        if (this._hasInputFocus) {
          utils.debouncedUpdate();
        }
      }
    }, true);

    window.addEventListener('focusout', e => {
      if (this._hasInputFocus) {
        this._hasInputFocus = false;
        utils.debouncedUpdate();
      }
    }, true);
    return
  },
  hasInputFocus() {
    return this._hasInputFocus;
  },
  storage: {
    noteComment: {
      _value: {},
      setup() {
        let json = localStorage.getItem(spat.config.storageKey.noteComment) || '{}';
        let value = JSON.parse(json);
        app.utils.storage.noteComment._value = value;
      },
      get(id) {
        return app.utils.storage.noteComment._value[id];
      },
      set(id, value) {
        app.utils.storage.noteComment._value[id] = value;
        localStorage.setItem(spat.config.storageKey.noteComment, JSON.stringify(app.utils.storage.noteComment._value));
      },
      delete(id) {
        delete app.utils.storage.noteComment._value[id];
        localStorage.setItem(spat.config.storageKey.noteComment, JSON.stringify(app.utils.storage.noteComment._value));
      },
      deleteAll() {
        app.utils.storage.noteComment._value = {};
        localStorage.removeItem(spat.config.storageKey.noteComment);
      }
    }
  },
  //- 1番 親要素のoffsetを取得
  cumulativeOffset: (element, root) => {
    var offset_top = 0;
    do {
      offset_top += element.offsetTop || 0;
      element = element.offsetParent;
    } while (element && root !== element);
    return offset_top;
  },

  // URLかどうかチェック
  isURL: (str) => {
    const pattern = /^https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+$/;
    return pattern.test(str);
  },
  handlers: {
    doubleTapIgnoreSelector: 'a, button, [role=button], .cursor-pointer, iframe, video, audio, input',
    generateDoubleTap: (onDoubleTap, ms = 384) => {
      let tapped = false;
      return (e) => {
        // クリッカブルな要素はスルーする
        if (e.target.closest(utils.handlers.doubleTapIgnoreSelector)) return;
        // シングルタップ判定
        if (!tapped) {
          tapped = true;
          // iPhone でクリックが遅くなる現象の対策で二重でsetTimeoutを入れる
          setTimeout(() => {
            setTimeout(() => {
              tapped = false;
            }, ms);
          }, 1);
        }
        // ダブルタップ判定
        else {
          onDoubleTap(e);
        }
      };
    },
  },
  // 名前の設定がまだであればモーダルを開く
  async openModalNameSettingIfNeeded(user) {
    //- nameが無ければ設定するmodalを出す
    if (user.screen_name) return;

    spat.modal.open('modal-profile', {
      dismissible: false,
    });
  },

  //- カーソルの位置にテーブル生成popupを開く
  openCursorPopupTable(editor) {
    const $cursor = editor.getCursor();
    const cursorPosition = $cursor.getBoundingClientRect();

    const popup = spat.popup.open('popup-create-table', {
      offsetTop: cursorPosition.top - 200,
      offsetLeft: cursorPosition.left - 40,
      align: {
        x: 'center'
      },
    }).on('submit', ({ rows, cols }) => {
      editor.util.insertMarkdownTable(rows, cols);
      popup.close();
    });
  },

  // 履歴のローカルストレージ周り
  history: {
    STORAGE_KEY: 'history_data',
    DEFAULT_VALUE: { notes: [], projects: [] },
    LIMIT: 5,

    save(data) {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
    },

    load() {
      let json = localStorage.getItem(this.STORAGE_KEY);
      if (!json) return this.DEFAULT_VALUE;
      try {
        return JSON.parse(json);
      }
      catch (err) {
        return this.DEFAULT_VALUE;
      }
    },

    addProject(id) {
      let history_data = this.load();
      history_data.projects.unshift(id);
      history_data.projects = utils.unique(history_data.projects).slice(0, this.LIMIT);
      this.save(history_data);
    },

    addNote(path) {
      let history_data = this.load();
      history_data.notes.unshift(path);
      history_data.notes = utils.unique(history_data.notes).slice(0, this.LIMIT);
      this.save(history_data);
    }
  },

  // メンションのソート
  // NOTE: 1.最後にコメントした人 2.アサイン 3.作成者
  formatMentions(mentions, note) {
    mentions = [...mentions];

    const current_user_id = app.store.currentUser.id;
    const last_commented_user_id = note.data.last_commented_user_id;
    const assign_id_set = new Set(note.data.assigned_user_refs.map(ref => ref.id));
    // アサインされているか
    const is_assigned = (mention) => assign_id_set.has(mention.item.id);

    // 最後にコメントしたか
    const is_last_commented = (mention) => {
      if (!note.data.message_count) return false;

      return last_commented_user_id === mention.item.id && last_commented_user_id !== current_user_id;
    };

    // ラベルを付与
    mentions.forEach(mention => {
      if (mention.type !== 'user') return;

      if (is_last_commented(mention)) {
        mention.label = "最新コメント";
      } else if (is_assigned(mention)) {
        mention.label = "アサイン";
      } else if (note.data.created_user_id === mention.item.id) {
        mention.label = "作成者";
      }
    });

    mentions.sort((a, b) => {
      // typeがuserでない場合はソートをスキップ
      if (a.type !== 'user' || b.type !== 'user') return 0;

      // 最後にコメントした人
      if (is_last_commented(a)) return -1;
      if (is_last_commented(b)) return 1;

      // アサインされている人たち
      if (is_assigned(a) && !is_assigned(b)) {
        return -1;
      }
      if (!is_assigned(a) && is_assigned(b)) {
        return 1;
      }

      // 作成者
      if (note.data.created_user_id === a.item.id) {
        return -1;
      }
      if (note.data.created_user_id === b.item.id) {
        return 1;
      }

      return 0;
    });

    return mentions;
  },

  // 渡ってきた要素のtopへのスクロール処理
  scrollToTop(element) {
    //- 現在の位置を取得
    let position = element.scrollTop;
    //- positionが0以外の時
    if (position !== 0) {
      //- スクロールアニメーション
      $(element).animate({ scrollTop: 0 }, 256);
    }
  },

};

export default utils;
